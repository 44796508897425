import React, { useEffect, useState } from 'react';
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, useParams, useNavigate } from 'react-router-dom';
import '../Style/Solicitudes/CurvaEstandar.css'
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import Search from '../Assets/UPLOAD.png';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import { Alert } from '../Components/Alert';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const CurvaEstandar = ({token, setId}) => {
  const { id, id_ensayo,  id_intento  } = useParams();
  setId(id);
  const [data, setData] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [intentoId, setIntentoId] = useState(id_intento);
  const [intentos, setIntentos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.id]: e.target.value,
    }));
  };


  const patchData = async (id_curva, fecha_preparacion, kit_enzima, tratamiento, disolvente, partidor_fw, partidor_rv, venc_tratamiento, venc_kit_enzima, venc_disolvente, venc_partidor_fw, venc_partidor_rv) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    setIsLoading(true);
    const response = await axios.patch(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/curva-estandar`, formData);
    console.log(response.data);

    if (response.data["Error"]) {
      setIsLoading(false);
      return;
    }
    console.log(response.data);
    setIsLoading(false);
    closeModal();
  };

  useEffect(() => {
    navigate(`/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/curva_estandar`);
  }, [intentoId]);

  const fetchData = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const config = {
      headers: { Authorization: `Bearer ${token.token}` },
    };
    setIsLoading(true);
    const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/curva-estandar`, config);

    if (response.data["Error"]) {
      setIsLoading(false);
      return;
    }

    if (response.data["errorMessage"]) {
      setIsLoading(false);
      return;
    }

    if (response.data["statusCode"] === 404){
      setIsLoading(false);
      return;
    }

    setData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    navigate(`/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/curva_estandar`);
  }, [intentoId]);

  const fetchIntentos = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const config = {
        headers: { Authorization: `Bearer ${token.token}` },
    };
    const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos`, config);

    if (response.data["Error"]) {
      setAlertIsOpen(true);
      setAlertMessage(response.data["Error"]);
      return;
    }
    setIntentos(response.data);
  };
  
  useEffect(() => {
    fetchIntentos();
  }, []);

  const handleIntento = (e) => {
    setIntentoId(e.target.value);
    console.log(intentoId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchData();
  };

  console.log(data && data["ct_deteccion"]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    fetchData();
  };

  let data_curva = [];

  if (data) {
    const log = data["curva_estandar"]["log_conc"];
    const ct = data["curva_estandar"]["ct_corregido"];
    data_curva = log.map((item, index) => ({ x: item.toFixed(3), y: ct[index].toFixed(3) }));
    console.log("data_curva");
    console.log(data_curva);
  }

  const data_2 = {
    datasets: [
      {
        label: '',
        data: [ data_curva
        ],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        display: true,
        title: {
          display: true,
          text: 'Copias de DNA/mL',
          color: 'black',
        },
        ticks: {
          callback: (value, index, values) => {
            return `10e${value}`;
          },
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        display: true,
        title: {
          display: true,
          text: 'Ct',
          color: 'black',
          }
      },
    },
  };

  return (
    <div className='Page'>
      <div className={modalIsOpen ? 'darken-background' : ''}>
        <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Subir archivo"
          className="modal-qpcr_2"
          style={{
            overlay: {
              zIndex: 9998,
            },
            content: {
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
            },
          }}
        >
        <form onSubmit={handleSubmit}>
        <h4 className='curva_title'>Información Curva Estándar</h4>
            <table className='formulario_1_input'>
              <thead>
                <tr>
                  <th>ID curva</th>
                  <th>Fecha preparación</th>
                </tr>
              </thead>
              <tbody>
                <tr key="1">
                <td><input type="text" id="id_curva" onChange={handleInputChange} /></td>
                <td><input type="text" id="fecha_preparacion" onChange={handleInputChange} /></td>
                </tr>
              </tbody>
            </table>
          <div className='titulo_y_boton'> 
            <h4 className='curva_title'>Reactivos utilizados</h4>
            <button classname='boton_subir'>Subir</button>
          </div>
          <table className='formulario_2_input'>
            <thead>
              <tr>
                <th>Reactivo</th>
                <th>Fecha alícuota</th>
                <th>Fecha venc</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>TRAT_01</td>
                <td><input type="text" id="tratamiento" onChange={handleInputChange} /></td>
                <td><input type="text" id="venc_tratamiento" onChange={handleInputChange} /></td>
              </tr>
              <tr>
                <td>KIT ENZIMA</td>
                <td><input type="text" id="kit_enzima" onChange={handleInputChange} /></td>
                <td><input type="text" id="venc_kit_enzima" onChange={handleInputChange} /></td>
              </tr>
              <tr>
                <td>DISOLVENTE</td>
                <td><input type="text" id="disolvente" onChange={handleInputChange} /></td>
                <td><input type="text" id="venc_disolvente" onChange={handleInputChange} /></td>
              </tr>
              <tr>
                <td>Partidor FW</td>
                <td><input type="text" id="partidor_fw" onChange={handleInputChange} /></td>
                <td><input type="text" id="venc_partidor_fw" onChange={handleInputChange} /></td>
              </tr>
              <tr>
                <td>Partidor RV</td>
                <td><input type="text" id="partidor_rv" onChange={handleInputChange} /></td>
                <td><input type="text" id="venc_partidor_rv" onChange={handleInputChange} /></td>
              </tr>
            </tbody>
          </table>
        </form>
        </Modal>
      </div>
      <div className='loading-div-ensayos'>
        <ClipLoader
            color={'#0052D1'}
            loading={isLoading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
      </div>
      <div className='container-principal-curva'>
        <div className='grafico_y_tablas'>
            <div className='tablas'>
              <div className='boton_y_titulo'>
                <h5 className='curva_title'>Curva Estándar Ensayo {id_ensayo} </h5>
                <div className='title-and-button-ensayos-qpcr-select'>
                  <select className='select-intento-curva' value={intentoId} onChange={handleIntento}>
                    {console.log(intentos)}
                    {intentos.map((i, index) => (
                      <option className='option-intento' key={i["id"]} value={i["id"]}>Intento {index+1}</option>
                    ))}
                    {intentos.length === 0 && <option className='option-intento' value={0}>Intento N/A</option>}
                  </select>
                </div>
                <div className='title-and-button-ensayos-qpcr-button'>
                  <div onClick={openModal}className='button-raw-grid' id="boton-esquina-curva">
                    <div className='button-raw-icon'>
                      <img src = {Search} className="icon-raw-grid" />
                    </div>
                    <div className='button-raw-title'>
                      <strong>Subir formulario</strong>
                    </div>
                  </div>
                </div>
              </div>
                <Scatter data={data_curva.length > 0 ? { datasets: [{ ...data_2.datasets[0], data: data_curva }] } : data_2} options={options} />
                <h4 className='parametros_reg_lineal_2'>Reactivos utilizados</h4>
                <table className='tabla_nuevos_datos_2'>
                  <thead>
                    <tr>
                      <th>Reactivo</th>
                      <th>Lote o fecha alícuota</th>
                      <th>Fecha venc</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>TRAT_01</td>
                      <td>{data && data["reactivos_utilizados"]["tratamiento"]}</td>
                      <td>{data && data["reactivos_utilizados"]["venc_tratamiento"]}</td>
                    </tr>
                    <tr>
                      <td>KIT ENZIMA</td>
                      <td>{data && data["reactivos_utilizados"]["kit_enzima"]}</td>
                      <td>{data && data["reactivos_utilizados"]["venc_kit_enzima"]}</td>
                    </tr>
                    <tr>
                      <td>DISOLVENTE</td>
                      <td>{data && data["reactivos_utilizados"]["disolvente"]}</td>
                      <td>{data && data["reactivos_utilizados"]["venc_disolvente"]}</td>
                    </tr>
                    <tr>
                      <td>Partidor FW</td>
                      <td>{data && data["reactivos_utilizados"]["partidor_fw"]}</td>
                      <td>{data && data["reactivos_utilizados"]["venc_partidor_fw"]}</td>
                    </tr>
                    <tr>
                      <td>Partidor RV</td>
                      <td>{data && data["reactivos_utilizados"]["partidor_rv"]}</td>
                      <td>{data && data["reactivos_utilizados"]["venc_partidor_rv"]}</td>
                    </tr>
                  </tbody>
                </table>
            </div>

          
          <div className='tablas'>
          <div className='graf_y_titulo'>
            <h4 className='parametros_reg_lineal_i'>Valores límite de Controles</h4>
            <table>
              <thead>
                <tr>
                  <th>Min. Ct de NTC</th>
                </tr>
              </thead>
              <tbody>
                <tr key="1">
                  <td>{data && data["valores_min_controles"]["min_ct_ntc"].toFixed(3)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='graf_y_titulo'>
            <h4 className='parametros_reg_lineal'>Parámetros de regresión lineal</h4>
            <table>
              <thead>
                <tr>
                  <th>Pendiente</th>
                  <th>COEF</th>
                  <th>R2</th>
                  <th>Eficiencia</th>
                </tr>
              </thead>
              <tbody>
                <tr key="1">
                  <td>{data && data["curva_estandar"]["pendiente"].toFixed(3)}</td>
                  <td>{data && data["curva_estandar"]["coeficiente"].toFixed(3)}</td>
                  <td>{data && data["curva_estandar"]["r2"].toFixed(3)}</td>
                  <td>{data && data["curva_estandar"]["eficiencia"].toFixed(3)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='graf_y_titulo'>
            <h4 className='parametros_reg_lineal'>Valores límite de Ct para detección</h4>
            <table>
              <thead>
                <tr>
                  <th>Ct (Dsup[])</th>
                  <th>Ct (Dinf[])</th>
                </tr>
              </thead>
              <tbody>
                <tr key="1">
                  <td>{data && data["ct_deteccion"]["d_sup"].toFixed(3)}</td>
                  <td>{data && data["ct_deteccion"]["d_inf"].toFixed(3)}</td>
                </tr>
              </tbody>
            </table>
            </div>
            <div className='graf_y_titulo'>
            <h4 className='parametros_reg_lineal'>Valores límite de Ct para cuantificación</h4>
            <table>
              <thead>
                <tr>
                  <th>Ct (Csup[])</th>
                  <th>Ct (Cinf[])</th>
                </tr>
              </thead>
              <tbody>
                <tr key="1">
                  <td>{data && data["ct_cuantificacion"]["c_sup"].toFixed(3)}</td>
                  <td>{data && data["ct_cuantificacion"]["c_inf"].toFixed(3)}</td>
                </tr>
              </tbody>
            </table>
            <h4 className='parametros_reg_lineal'>Información Curva Estándar</h4>
            <table className='tabla_nuevos_datos_1'>
              <thead>
                <tr>
                  <th>ID curva</th>
                  <th>Fecha preparación</th>
                </tr>
              </thead>
              <tbody>
                <tr key="1">
                <td>{data && data["info_form"]["id_curva"]}</td>
                <td>{data && data["info_form"]["fecha_preparacion"]}</td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
