import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Fondo_Phagelab from '../Assets/Fondo_Phagelab.jpg';

export const About = ( token ) => {
    const [data, setData] = useState('');
    
    const fetchData = async () => {
        const config = {
          headers: { Authorization: `Bearer ${token.token}` },
        };
        console.log(token);
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/hello-world`, config);
        setData(response.data.body);
        console.log(response.data);
      }
      
    useEffect(() => {
        if (token.token){
            fetchData()
        }
    }, [])
    return (
        <div className='background' style={{
            backgroundImage:`url(${Fondo_Phagelab})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            bachgroundColor: 'white',
        }}>
            <div className='Page'>
                <h1>About</h1>
                <h2 style={{ color: 'white' }}>{token.token ? data : "No autorizado"}</h2>
            </div>
        </div>
    )
}