// create component showing Logo.png
import React from 'react';
import LogoW from '../Assets/PHAGE-LOGO-WHITE.png';
import LogoB from '../Assets/PHAGE-LOGO-BLACK.png';
import '../Style/LogoPhage.css';
export const LogoPhage = ({color='white'}) => {
    if (color=='black') {
        return (
            <div className="LogoPhage">
                <img className='LogoImg' src={LogoB} ></img>
            </div>
        )  
    } else {
        return (
            <div className="LogoPhage">
                <img className='LogoImg' src={LogoW} ></img>
            </div>
        )
    }
}