import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolId = process.env.REACT_APP_API_USER_POOL_ID;
const clientId = process.env.REACT_APP_API_CLIENT_ID;

const poolData = {
    UserPoolId: poolId,
    ClientId: clientId,
}

export default new CognitoUserPool(poolData);