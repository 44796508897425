import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../Style/Solicitudes/Solicitud.css'

export const Intentos = (token) => {
    const { id, id_ensayo} = useParams();
    const [data, setData] = useState('');
    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
          };
        const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos`, {"id_solicitud":id, "id_ensayo":id_ensayo}, config);

        if (response.data["errorMessage"]) {
            alert("Error: " + response.data["errorMessage"] + " Tipo: " + response.data["errorType"] );
            return;
        }

        setData(response.data);
        console.log(response.data);
    }
    useEffect(() => {
        fetchData()
    }, [])
          

    
return;
}