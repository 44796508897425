import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../Style/Cuantificacion.css'
import { ClipLoader } from 'react-spinners';
import { Alert } from '../Components/Alert';


export const Cuantificacion = ({token, setId}) => {
    const { id, id_ensayo, id_intento } = useParams();  // ids de la ruta
    setId(id); // id para navbar
    const [intentoId, setIntentoId] = useState(id_intento); // variable de intento
    const [intentos, setIntentos] = useState([]); // lista de intentos
    const [data, setData] = useState(''); // datos de request GET
    const [isLoading, setIsLoading] = useState(false); // loading
    const [alertIsOpen, setAlertIsOpen] = useState(false); // alerta
    const [alertMessage, setAlertMessage] = useState(""); // mensaje de alerta
    const navigate = useNavigate(); // hook para navegar entre rutas

    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
          };
        setIsLoading(true);
        const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/cuantificacion`, config);
        console.log(response.data);
        if (response.data["statusCode"] === 404) {
            setAlertMessage(response.data["message"]);
            setAlertIsOpen(true);
            setIsLoading(false);
            return;
        }
        if (response.data["errorMessage"]) {
            setAlertMessage(response.data["errorMessage"]);
            setAlertIsOpen(true);
            setIsLoading(false);
            return;
        }

        setData(response.data);
        setIsLoading(false);
    }
    
    const patchData = async (index) => { // patch request para omitir pocillo
        
        const omitir = !data[index][11];
        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
            };
        const body = {
            "pocillo": data[index][0],
            "omitir": omitir
        }
        console.log(body);
        setIsLoading(true);

        const response = await axios.patch(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/cuantificacion`, body, config);
        console.log(response.data);
        if (response.data["errorMessage"]) {
            setAlertMessage(response.data["errorMessage"]);
            setAlertIsOpen(true);
            setIsLoading(false);
            return;
        }
        if (response.data["statusCode"] === 400) {
            setAlertMessage(response.data["message"]);
            setAlertIsOpen(true);
            setIsLoading(false);
            return;
        }
        if (response.data["statusCode"] === 404) {
            setAlertMessage(response.data["message"]);
            setAlertIsOpen(true);
            setIsLoading(false);
            return;
        }
        if (response.data["statusCode"] === 200) {
            setAlertMessage(response.data["message"]);
            setAlertIsOpen(true);
            setIsLoading(false);
            fetchData();
            return;
        }
    }


    useEffect(() => {
        navigate(`/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/cuantificacion`);
        fetchData();
    }, [intentoId]);

    const fetchIntentos = async () => {
        console.log("Fetching intentos");
        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
        };
        const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos`, config);
        console.log(response.data);
    
        if (response.data["Error"]) {
            setAlertMessage(response.data["Error"]);
            setAlertIsOpen(true);
            setIntentos([]);
            return;
        }
        setIntentos(response.data);
    };

    useEffect(() => { // hook para cargar intentos al cargar la pagina
        fetchIntentos();
    }, []);

    const handleCheckboxChange = (index) => {
        patchData(index);
    };

    const handleIntento = (e) => {
        setIntentoId(e.target.value);
        console.log(intentoId);
    };
          

    
    return (
    <div className="solicitud_container">
        <div className='title-and-button-ensayos-cuantificacion'>
            <div className='title-and-button-ensayos-cuantificacion-title'>
                <h2 className="title-cuantificacion">Cuantificación Absoluta qPCR Ensayo {id_ensayo}</h2>
            </div>
            <div>
                <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            </div>

            <div className='loading-div-ensayos'>
                <ClipLoader
                    color={'#0052D1'}
                    loading={isLoading}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
            <span></span>
            <div className='title-and-button-ensayos-cuantificacion-select'>
                <select className='select-intento' value={intentoId} onChange={handleIntento}>
                    {intentos && intentos.map((i, index) => (
                    <option className='option-intento' key={i["id"]} value={i["id"]}>Intento {index+1}</option>
                    ))}
                    {intentos.length === 0 && <option className='option-intento' value={0}>Intento N/A</option>}
                </select>
            </div>
            <div className="tabla_cuantificacion">
                <table className='tabla'>
                    <thead>
                        <tr>
                            <th className='th'>Pocillo</th>
                            <th className='th'>Muestra</th>
                            <th className='th'>Ct</th>
                            <th className='th'>Ct(Dsup[])</th>
                            <th className='th'>Ct(Dinf[])</th>
                            <th className='th'>Eval. Tm</th>
                            <th className='th'>Detección</th>
                            <th className='th'>Cantidad de copias</th>
                            <th className='th'>Control de calidad</th>
                            <th className='th'>Concentración</th>
                            <th className='th'>Unidad</th>
                            <th className='th'>¿Omitir?</th>
                            <th className='th'>¿Valor extrapolado?</th>
                            <th className='th'>QC NTC</th>
                            <th className='th'>Eficiencia</th>
                            <th className='th'>R2</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data && data.map((muestra, index) => (
                            <tr key={muestra.id}>
                                <td className="td" id="Pocillo">{muestra[0]}</td>
                                <td className="td" id="Muestra">{muestra[1]}</td>
                                <td className="td" id="Ct">{muestra[2].toFixed(2)}</td>
                                <td className="td" id="Ct(Dsup[])">{muestra[3].toFixed(7)}</td>
                                <td className="td" id="Ct(Dinf[])">{muestra[4].toFixed(2)}</td>
                                <td className="td" id="Eval. Tm">{muestra[5]}</td>
                                <td className="td" id="Detección">{muestra[6]}</td>
                                <td className="td" id="Cantidad (ADN/reacción)">{muestra[7].toFixed(6)}</td>
                                <td className="td" id="Control de calidad">{muestra[8]}</td>
                                <td className="td" id="Concentración">{muestra[9]}</td>
                                <td className="td" id="Unidad">{muestra[10]}</td>
                                <td className="td" id="Omitir" ><input type="checkbox" checked={muestra[11]} onChange={() => handleCheckboxChange(index)}/></td>
                                <td className="td" id="Valor extrapolado">{muestra[12]}</td>
                                <td className="td" id="QC NTC">{muestra[13]}</td>
                                <td className="td" id="Eficiencia">{muestra[14]}</td>
                                <td className="td" id="R2">{muestra[15]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    
)
}
