import React, { useState } from 'react';
import {CreateSolicitud} from '../Components/Solicitudes/Create_solicitud';
import {ViewSolicitudes} from '../Components/Solicitudes/ViewSolicitudes';
import '../Style/general.css';
export const Solicitudes = (token) => {
    const [solicitudActualizada, setSolicitudActualizada] = useState(false);
    return (
        <div className='Page'>
            <div className='container-principal'>
                <div className='container-principal-esp-1'></div>
                <div className='container-principal-col-1'>
                    <CreateSolicitud setSolicitudActualizada={setSolicitudActualizada} token={token}></CreateSolicitud>
                </div>
                <div className='container-principal-col-2'>
                    <ViewSolicitudes solicitudActualizada={solicitudActualizada} token={token}></ViewSolicitudes>
                </div>
                <div className='container-principal-esp-1'></div>
            </div>
        </div>
    )
}