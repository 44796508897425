import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../Style/Login/LoginForm.css';
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import { Alert } from '../Alert';

export const ChangePasswordForm = () => {


    let [email, setEmail] = useState("");
    let [code, setCode] = useState("");
    let [newPassword, setNewPassword] = useState("");
    const [alertIsOpen, setAlertIsOpen] = useState(true);
    const [alertMessage, setAlertMessage] = useState("La nueva contraseña debe tener al menos: 8 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 número y 1 símbolo");

    
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
      
        const userData = {
          Username: email,
          Pool: UserPool,
        };
        const cognitoUser = new CognitoUser(userData);
      
        const promise = new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(code, newPassword, {
                  onFailure: (err) => {
                    console.log("onFailure:" + err);
                    setAlertMessage(err.message);
                    setAlertIsOpen(true);
                  },
                  onSuccess: (res) =>  {
                    console.log("onSuccess:");
                    resolve();
                    navigate("/");
                  },
                  undefined
              });
          });
          return promise;
      };

    return (
        <div className="Vertical">
            <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <form onSubmit={handleSubmit}>
                <div className="Horizontal">
                    <h2>Change Password</h2>
                </div>
                <div className="Horizontal">
                    <div className="Text">
                        <p className="Left">E-mail</p>
                    </div>
                </div>
                <div className="Horizontal">
                    <input type="text" className="input"  required placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)}></input>
                </div>
                <div className="Horizontal">
                    <div className="Text">
                        <p className="Left">Reset Code</p>
                    </div>
                </div>
                <div className="Horizontal">
                    <input type="text" className="input"  required placeholder="Enter your reset code" onChange={(e) => setCode(e.target.value)}></input>
                </div>
                <div className="Horizontal">
                    <div className="Text">
                        <p className="Left">New password</p>
                    </div>
                </div>
                <div className="Horizontal">
                    <input type="password" className="input" required placeholder="Enter your new password" onChange={(e) => setNewPassword(e.target.value)}></input>
                </div>
                <div className="Horizontal">
                    <div className="ElementSign">
                        <button className="Sign" type="Submit">Confirm</button> 
                    </div>  
                </div>
            </form>
        </div>
    )
}
