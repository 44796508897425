import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from '../Components/Alert';
import axios from 'axios';
import '../Style/Reporte.css';
// import Uint8Array from 'uint8arrays';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';

Modal.setAppElement('#root');


export const Reporte = ({token, setId}) => {

    const { id, id_ensayo, id_intento } = useParams();
    setId(id);

    const [intentoId, setIntentoId] = useState(id_intento);
    const [intentos, setIntentos] = useState([]);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [data, setData] = useState('');
    const [dataHeaders, setDataHeaders] = useState('');
    const [formData, setFormData] = useState({});
    const [observacion, setObservacion] = useState('');
    const [tipoMuestra, setTipoMuestra] = useState('');
    const [metodo, setMetodo] = useState('');


    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL

    // ********** Intentos **********
    const fetchIntentos = async () => {
      console.log("Fetching intentos");
      const apiUrl = process.env.REACT_APP_API_URL;
      const config = {
          headers: { Authorization: `Bearer ${token.token}` },
      };
      const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos`, config);
      console.log("Response intentos:");
      console.log(response.data);
      setIntentos(response.data);
    };

    const handleIntento = (e) => {
      setIntentoId(e.target.value);
    };

    
    useEffect(() => {
      fetchData();
      fetchDataHeaders();
      // navigate(`/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/reporte`);
    }, [intentoId]);


    // ********** Data Análisis **********
    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
          };
        setIsLoading(true);
        const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/report/table`, config);
        

        if (response.data["errorMessage"]) {
            console.log(response.data);
            setAlertMessage("Error es posible que no exista un intento para este ensayo");
            setAlertIsOpen(true);
            setIsLoading(false);
            return;
        }

        setData(response.data);
        console.log("Data fetch: " + JSON.stringify(response.data));
        setIsLoading(false);
    }

    const data_analisis = data["table"];

    // ********** Data Headers **********
    const fetchDataHeaders = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const config = {
          headers: { Authorization: `Bearer ${token.token}` },
        };
      setIsLoading(true);
      const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/report/headers`, config);
      

      if (response.data["errorMessage"]) {
          console.log(response.data);
          setAlertMessage("Error es posible que no exista un intento para este ensayo");
          setAlertIsOpen(true);
          setIsLoading(false);
          return;
      }

      setDataHeaders(response.data);
      console.log("Data headers: " + JSON.stringify(response.data));
      
      setIsLoading(false);
    }

    useEffect(() => {
      fetchIntentos();
    }, []);

    const data_reporte = dataHeaders;

    // ********** Editar Headers ********** --> observaciones, tipo_muestra, metodo_usado
    const handleChangeObservacion = (obs) => {
      setObservacion(obs.target.value);
    };

    const handleChangeMuestra = (muestra) => {
      setTipoMuestra(muestra.target.value);
    };

    const handleChangeMetodo = (metodo) => {
      setMetodo(metodo.target.value);
    };

    const handleSubmit = async (data) => {
      try {
        const data = {
          "observaciones": observacion,
          "tipo_muestra": tipoMuestra,
          "metodo_usado": metodo
        };

        const response = await axios.patch(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/report`, data);
        console.log("Patch response: " + response.data);
        setIsLoading(false);
        closeModal();
        fetchDataHeaders();
      } catch (error) {
        console.error(error); // Maneja el error según tus necesidades
      }
    };

    const handleFormSubmit = (event) => {
      event.preventDefault();
      handleSubmit(formData);
    };
    
    // ********** MODAL Editar Headers **********

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
      setModalIsOpen(false);
      console.log("Cerrado modal");
    };



    // ********** Obtención de PDF **********
    const handleDescargarPDF = async () => {
      if (intentos == 0) {
        setAlertMessage("No se puede descargar un reporte sin intentos");
        setAlertIsOpen(true);
        return;
      }
      const headers = {
        Authorization: `Bearer ${token.token}`,
        'Content-Type': 'application/json'
      };

      const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
          };
        setIsLoading(true);
      
      try {
        /*
        // Para cuando era con post
        var response = null;
        if (data_reporte.metodo_usado & data_reporte.observaciones & data_reporte.tipo_muestra) {
          response = await axios.post(
            `${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${id_intento}/report`,
            {
              "observaciones": data_reporte.observaciones,
              "tipo_muestra": data_reporte.tipo_muestra,
              "metodo_usado": data_reporte.metodo_usado
            },
            { headers }
          );
        }
        else {
        response = await axios.post(
          `${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${id_intento}/report`,
          { headers }
        );
        }
        */

        const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/report`, config);
        const array = await response.data;
        
        console.log(array.file);

        descargarPDFDesdeByteArray(array.file, `REPORTE-S${id}-E${id_ensayo}-I${intentoId}.pdf`);

      } catch (error) {
        console.error('Error al realizar la solicitud POST', error);
      }
      finally {
        setIsLoading(false);
      }
    };

    // ********** Descarga de PDF **********
    function descargarPDFDesdeByteArray(byteArray, nombreArchivo) {
      
      var uint8 = new Uint8Array(byteArray);

      // Crea un Blob a partir del ByteArray
      var blob = new Blob([uint8], { type: 'application/pdf' });
    
      // Crea una URL temporal para el Blob
      const url = URL.createObjectURL(blob);
    
      // Crea un elemento <a> para simular un enlace de descarga
      const enlaceDescarga = document.createElement('a');
      enlaceDescarga.href = url;
      enlaceDescarga.download = nombreArchivo;
    
      // Simula un clic en el enlace de descarga
      enlaceDescarga.click();
    
      // Libera la URL temporal
      URL.revokeObjectURL(url);
    }
      
    
    return (
    <div className="solicitud_container">
        <div className='loading-div-ensayos'>
          <ClipLoader
              color={'#0052D1'}
              loading={isLoading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
          />
        </div>
        <div>
            <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </div>
        <div className='grid-titulo-descarga'>
          <div className='titulo'>
            <h2 className='info-titulo'><strong>Reporte de Cuantificación Específica</strong></h2>
          </div>
          <div className='descarga'>
            <div className='grid-select-intentos-reporte'>
              
              <div className='select-intento-reporte'>
                <select className='select-intento' value={intentoId} onChange={handleIntento}>
                  {intentos.map((i, index) => (
                    <option className='option-intento' key={i["id"]} value={i["id"]}>Intento {index+1}</option>
                  ))}
                  {intentos.length === 0 && <option className='option-intento' value={0}>Intento N/A</option>}
                </select>
              </div>
              
              <div className='button-descarga-reporte'>
                <button onClick={handleDescargarPDF}>Descargar PDF</button>
              </div>
            </div>
          </div>
        </div>
      
        <div className="primera_tabla_container">
            <table>
                <thead>
                    <tr>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>

        <div className="grid-info-reporte">
          <div className='cliente'>
            <p className='info-reporte'><strong>Cliente:</strong></p>
          </div>
          <div className='cliente-info'>
            <p className='info-reporte'>{data_reporte.cliente}</p>
          </div>
          <div className='columna1-encabezado'>
            <p className='info-reporte'><strong>Dirección:</strong></p>
            <p className='info-reporte'><strong>Nombre Solicitante:</strong></p>
            <p className='info-reporte'><strong>Orden de Trabajo:</strong></p>
            <p className='info-reporte'><strong>Tipo de Muestra:</strong></p>
            <p className='info-reporte'><strong>Toma de Muestra por:</strong></p>
            <p className='info-reporte'><strong>Método Usado:</strong></p>
          </div>

          <div className='columna1-info'>
            <p className='info-reporte'>{data_reporte.direccion}</p>
            <p className='info-reporte'>{data_reporte.solicitante}</p>
            <p className='info-reporte'>{data_reporte.orden_trabajo}</p>
            <p className='info-reporte'>{data_reporte.tipo_muestra ? data_reporte.tipo_muestra : "POR RELLENAR"}</p>
            <p className='info-reporte'>{data_reporte.toma_muestra_por}</p>
            <p className='info-reporte'>{data_reporte.metodo_usado ? data_reporte.metodo_usado : "POR RELLENAR"}</p>
          </div>

          <div className='columna2-encabezado'>
            <p className='info-reporte'><strong>Número de Muestras:</strong></p>
            <p className='info-reporte'><strong>Fecha de Recepción de Muestras:</strong></p>
            <p className='info-reporte'><strong>Fecha de Inicio de Análisis:</strong></p>
            <p className='info-reporte'><strong>Fecha de Término de Análisis:</strong></p>
            <p className='info-reporte'><strong>Fecha de Emisión de Reporte:</strong></p>
            <p className='info-reporte'><strong>Ensayo Solicitado:</strong></p>
          </div>

          <div className='columna2-info'>
            <p className='info-reporte'>{data_reporte.num_muestras}</p>
            <p className='info-reporte'>{data_reporte.fecha_recepcion}</p>
            <p className='info-reporte'>{data_reporte.fecha_inicio}</p>
            <p className='info-reporte'>{data_reporte.fecha_termino}</p>
            <p className='info-reporte'>{data_reporte.fecha_emision}</p>
            <p className='info-reporte'>{data_reporte.id_ensayo}</p>
          </div>

          <div className='observacion'>
            <p className='info-reporte-obs'><strong>Observaciones:</strong></p>
          </div>
          <div className='observacion-info'>
            <p className='info-reporte-obs'>{data_reporte.observaciones ? data_reporte.observaciones : "POR RELLENAR"}</p>
          </div>
          <div className='boton-editar'>
          <button className='info-reporte-obs' onClick={openModal}>Editar Información</button>
          </div>

        </div>

        <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Subir archivo"
          className="modal-reporte"
        >
          <form onSubmit={handleFormSubmit} className='form-modal-reporte'>
            <h2>Editar Información</h2>
            <br></br>
            <p>Observaciones:</p><input type="text" onChange={handleChangeObservacion} defaultValue={data_reporte.observaciones ? data_reporte.observaciones : ""} />
            <br></br>
            <br></br>
            <p>Tipo de Muestra:</p> <input type="text" name="tipo" onChange={handleChangeMuestra} defaultValue={data_reporte.tipo_muestra ? data_reporte.tipo_muestra : ""}/>
            <br></br>
            <br></br>
            <p>Método Usado:</p> <input type="text" name="metodo" onChange={handleChangeMetodo} defaultValue={data_reporte.metodo_usado ? data_reporte.metodo_usado : ""}/>
            <br></br>
            <br></br>
            <button className="info-reporte-obs" type="submit">Enviar</button>
          </form>
        </Modal>
      </div>
        
        
        <br></br>
        <h3 className='titulo-reporte'>Resultado de Análisis</h3>
        <div className="primera_tabla_container">
            <table>
                <thead>
                    <tr>
                        <th>ID muestra</th>
                        <th>Detección</th>
                        <th>Concentración Promedio - IC95</th>
                        <th>Unidad</th>
                        <th>Desviación Estándar</th>
                        <th>Concentración Mínima Entre Réplicas</th>
                        <th>Réplicas</th>
                        <th>CV Entre Réplicas</th>
                        <th>Biomarcador Evaluado</th>
                    </tr>
                </thead>
                <tbody>
                  {data_analisis && data_analisis.map((muestra) => (
                    <tr key={muestra.id_muestra}>
                      <td>{muestra[0]}</td>
                      <td>{muestra[1]}</td>
                      <td>{muestra[2]}</td>
                      <td>{muestra[3]}</td>
                      <td>{muestra[4]}</td>
                      <td>{muestra[5]}</td>
                      <td>{muestra[6]}</td>
                      <td>{muestra[7]}</td>
                      <td>{muestra[8]}</td>
                    </tr>
                  ))}
                </tbody>
            </table>
        </div>
    </div>
    
)
}
