import React from 'react';
import '../Style/Login/Signup.css';
import { SignupForm } from '../Components/Login/SignupForm';
import Fondo_Phagelab from '../Assets/Fondo_Phagelab.jpg';

export const Signup = () => {
    return (
        <div className='background' style={{
            backgroundImage:`url(${Fondo_Phagelab})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            bachgroundColor: 'white',
        }}>
        <div className='Page'>
            <h1>Registrar nuevo usuario</h1>
            <div className='row'>
                <SignupForm />
            </div>
        </div>
        </div>
    )
}