import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import '../Style/Solicitudes/Solicitud.css';
import '../Style/qpcrView.css';
import axios from "axios";
import Upload from '../Assets/UPLOAD.png';
import Modal from 'react-modal';
import { DragAndDropRaw } from '../Components/Solicitudes/DragAndDropRAW';
import { useNavigate } from "react-router-dom";
import { ClipLoader } from 'react-spinners';
import { Alert } from '../Components/Alert';

export const QpcrView = ({token, setId}) => {

  const { id, id_ensayo, id_intento } = useParams();
  setId(id);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [intentoId, setIntentoId] = useState(id_intento);
  const [intentos, setIntentos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  function isNotEmpty( dict ) {
    for ( var name in dict ) {
        return true;
    }
    return false;
  }

  const fetchData = async () => {
    console.log("Fetching data");
    const apiUrl = process.env.REACT_APP_API_URL;
    const config = {
      headers: { Authorization: `Bearer ${token.token}` },
    };
    setIsLoading(true);
    const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/qpcr`, config);
    console.log(response.data);

    if (response.data["Error"]) {
      setAlertMessage(response.data["Error"]);
      setAlertIsOpen(true);
      setData([{}]);
      setIsLoading(false);
      return;
    }

    if (response.data["errorMessage"]) {
      setAlertMessage(response.data["Error"]);
      setAlertIsOpen(true);
      setIsLoading(false);
      return;
    }

    if (response.data["statusCode"] === 404){
      setIsLoading(false);
      return;
    }
    else {
    setData(response.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // navigate(`/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/qpcr`);
  }, [intentoId]);

  const fetchIntentos = async () => {
    console.log("Fetching intentos");
    const apiUrl = process.env.REACT_APP_API_URL;
    const config = {
        headers: { Authorization: `Bearer ${token.token}` },
    };
    const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos`, config);
    console.log(response.data);

    if (response.data["Error"]) {
        setAlertMessage(response.data["Error"]);
        setAlertIsOpen(true);
        setIntentos([]);
        return;
    }
    setIntentos(response.data);
  };

  useEffect(() => {
    fetchIntentos();
  }, []);

  useEffect(() => {
    if (intentos.length > 0) {
      const lastIntentoId = intentos[intentos.length - 1].id;
      setIntentoId(lastIntentoId);
    }
  }, [intentos]);

  // Inicio modal para subir archivo qpcr

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    console.log("Cerrado modal");
    fetchIntentos();
  };

  const handleIntento = (e) => {
    setIntentoId(e.target.value);
    console.log(intentoId);
  };

  // Fin modal para subir archivo qpcr

  return (
    <div className="solicitud_container">
      <div className='title-and-button-ensayos-qpcr'>
        <div className='title-and-button-ensayos-qpcr-title'>
            <h2 className="title-qpcr">qPCR Ensayo {id_ensayo}</h2>
        </div>
        <div>
          <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </div>

        <div className="loading-div-qpcr">
          <ClipLoader
              color={'#0052D1'}
              loading={isLoading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
          />
        </div>
        <div className='title-and-button-ensayos-qpcr-select'>
          <select className='select-intento' value={intentoId} onChange={handleIntento}>
            {intentos && intentos.map((i, index) => (
              <option className='option-intento' key={i["id"]} value={i["id"]}>Corrida {index+1}</option>
            ))}
            {intentos.length === 0 && <option className='option-intento' value={0}>Corrida N/A</option>}
          </select>
        </div>
        <div className='title-and-button-ensayos-qpcr-button'>
          <div onClick={openModal} className='button-raw-grid' id="boton-esquina">
            <div className='button-raw-icon'>
              <img src={Upload} className="icon-raw-grid" />
            </div>
            <div className='button-raw-title'>
              <strong>Subir o actualizar archivo qPCR</strong>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Subir archivo"
          className="modal-qpcr"
        >
          <DragAndDropRaw token={token}></DragAndDropRaw>
        </Modal>
      </div>

      <div className="muestras_container">
        <table>
          <thead>
            <tr>
              <th>Pocillo</th>
              <th>ID Muestra</th>
              <th>CT</th>
              <th>TM</th>
              <th>Observación</th>
              <th>Threshold</th>
            </tr>
          </thead>
          <tbody>
            {isNotEmpty(data) && data.map((qpcr) => (
              <tr key={qpcr.id}>
                <td>{qpcr.pocillo}</td>
                <td>{qpcr.id_muestra}</td>
                <td>{parseFloat(qpcr.ct).toFixed(2)}</td>
                <td>{parseFloat(qpcr.tm).toFixed(2)}</td>
                <td>{qpcr.observacion}</td>
                <td>{parseFloat(qpcr.threshold).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
