import './Style/App.css';
import { Login } from './Pages/Login';
import { Routes, Route } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Navbar } from './Components/Navbar';
import { Signup } from './Pages/Signup';
import { About } from './Pages/About';
import { Solicitudes } from './Pages/Solicitudes';
import { Ensayos } from './Pages/Ensayos';
import { useState, useEffect } from 'react';
import { Solicitud } from './Pages/Solicitud';
import { Verify } from './Pages/Verify';
import { ForgotPassword } from './Pages/ForgotPassword';
import { ChangePassword } from './Pages/ChangePassword';
import { QpcrView } from './Pages/qpcrView';
import { CurvaEstandar } from './Pages/CurvaEstandar';
import { Cuantificacion } from './Pages/Cuantificacion';
import { Consolidado } from './Pages/Consolidado';
import { Intentos } from './Pages/Intentos';
import { Reporte } from './Pages/Reporte';

function App() {

  let [admin, setAdmin] = useState(() => localStorage.getItem('qcmdadmin') || false);
  let [token, setToken] = useState(() => localStorage.getItem('qcmdtoken') || null);
  const [id, setId] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('qcmdtoken');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    const storedAdmin = localStorage.getItem('qcmdadmin');
    if (storedAdmin) {
      setAdmin(storedAdmin);
    }
  }, []);

  return (
    <div className="App">
      <header className="AppHeader">
        <Navbar token={token} setToken={setToken} admin={admin} setAdmin={setAdmin} id={id} setId={setId}/>
      </header>
      <div className="AppBody">
          
        <Outlet/>
        <Routes>
          <Route path="/" element={<Login setToken={setToken} setAdmin={setAdmin} />} />
          <Route path="/solicitudes" element={<Solicitudes token={token}/>} />
          <Route path="/solicitudes/ensayos" element={<Ensayos token={token}/>} />
          <Route path="/about" element={<About token={token} />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/solicitudes/:id" element={<Solicitud token={token}/>} />
          <Route path="/solicitudes/:id/ensayos" element={<Ensayos token={token}/>} />
          <Route path="/solicitudes/:id/ensayos/:id_ensayo/intentos/:id_intento/qpcr" element={<QpcrView token={token} setId={setId} />} />
          <Route path="/solicitudes/:id/ensayos/:id_ensayo/intentos" element={<Intentos token={token}/>} />
          <Route path="/solicitudes/:id/ensayos/:id_ensayo/intentos/:id_intento/curva_estandar" element={<CurvaEstandar token={token} setId={setId}/>} />
          <Route path="/solicitudes/:id/ensayos/:id_ensayo/intentos/:id_intento/cuantificacion" element={<Cuantificacion token={token} setId={setId}/>} />
          <Route path="/solicitudes/:id/ensayos/:id_ensayo/intentos/:id_intento/consolidado" element={<Consolidado token={token} setId={setId}/>} />
          <Route path="/solicitudes/:id/ensayos/:id_ensayo/intentos/:id_intento/reporte" element={<Reporte token={token} setId={setId}/>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
