import React from 'react';
import '../Style/Login/Signup.css';
import { ForgotPasswordForm } from '../Components/Login/ForgotPasswordForm';
import Fondo_Phagelab from '../Assets/Fondo_Phagelab.jpg';

export const ForgotPassword = () => {
    return (
        <div className='background' style={{
            backgroundImage:`url(${Fondo_Phagelab})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            bachgroundColor: 'white',
        }}>
        <div className='Page'>
            <h1>Recuperar Contraseña</h1>
            <div className='row'>
                <ForgotPasswordForm />
            </div>
        </div>
        </div>
    )
}