import React, { useState, useEffect } from 'react';
import '../../Style/Solicitudes/ViewSolicitudes.css';
import ArrowDown from '../../Assets/FLECHA-ABAJO.png';
import ArrowUp from '../../Assets/FLECHA-ARRIBA.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SearchIcon from '../../Assets/SEARCH_WHITE.png';
import FlaskIcon from '../../Assets/FLASK_WHITE.png';
import ClipLoader from "react-spinners/ClipLoader";

export const ViewSolicitudes = ({ solicitudActualizada, token }) => {
  const [data, setData] = useState('');
  const [sortedData, setSortedData] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('fecha');
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const config = {
      headers: { Authorization: `Bearer ${token.token}` },
    };
    try {
      setIsLoading(true);
      const response = await axios.get(`${apiUrl}/solicitudes`, config);
      
      console.log(response.data);
      
      setData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('Unauthorized');
      } else {
        alert('Error fetching data');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const sort_data = (data) => {
    console.log(data);
    if (data["errorMessage"]) {
      alert(data["errorMessage"]);
    return;}
    return setSortedData([...data].sort((a, b) => b.id - a.id));
  };

  useEffect(() => {
    fetchData();
  }, [solicitudActualizada]);

  useEffect(() => {
    if (data && data.length > 0) {
      sort_data(data);
    }
  }, [data]);

  const [showLinks, setShowLinks] = useState(false);
  const [selectedSolicitudId, setSelectedSolicitudId] = useState(null);

  const handleSolicitudClick = (id) => {
    setSelectedSolicitudId(id);
    if (showLinks && selectedSolicitudId === id) {
      setShowLinks(false);
    } else {
      setShowLinks(true);
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchTerm(''); // Reiniciar el término de búsqueda al cambiar el tipo de búsqueda
  };

  let results = [];
  if (!searchTerm) {
    results = sortedData;
  } else {
    results = sortedData.filter((dato) =>
      searchType === 'fecha' ? dato.fecha_solicitud.includes(searchTerm) : dato.id.toString().includes(searchTerm)
    );
  }

  return (
    <div className="solicitudes">
      <div className="solicitudes_container">
        <div className="solicitudes_header">
          <h3 className="solicitudes_titulo">SOLICITUDES</h3>
          <div className="search-bar">
            <input
              className="input_search"
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Buscar..."
            />
            <select className="select_filter" value={searchType} onChange={handleSearchTypeChange}>
              <option value="fecha">Fecha</option>
              <option value="id">ID</option>
            </select>
          </div>
        </div>
        <div className="loading-div-solicitudes">
          <ClipLoader
            color={'#0052D1'}
            loading={isLoading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
        <div className="solicitudes_container">
          {results &&
            results.map((solicitud) => (
              <div className="solicitud" key={solicitud.id}>
                <div className="grid-botonera-solicitudes" onClick={() => handleSolicitudClick(solicitud.id)}>
                  <div className="titulo-bs">
                    { solicitud.id_solicitud ? <p className="solicitud_id">Solicitud {solicitud.id_solicitud}</p> : <p className="solicitud_id">Solicitud nº {solicitud.id}</p> }
                  </div>
                  <div className="prioridad-bs">
                    <p className="solicitud_prioridad">Prioridad: {solicitud.prioridad}</p>
                  </div>
                  <div className="fecha-bs">
                    <p className="solicitud_fecha">{solicitud.fecha_solicitud}</p>
                  </div>
                  <div className="espacio-bs"></div>
                  <div className="flecha-bs">
                    {showLinks && selectedSolicitudId === solicitud.id ? (
                      <span className="icon_flecha">
                        <img src={ArrowUp} />
                      </span>
                    ) : (
                      <span className="icon_flecha">
                        <img src={ArrowDown} />
                      </span>
                    )}
                  </div>
                </div>

                {showLinks && selectedSolicitudId === solicitud.id && (
                  <div className="grid-botonera-solicitudes-links">
                    <div className="espacio1-bsl"></div>

                    <div className="columna-links-bsl">
                      <Link to={`/solicitudes/${solicitud.id}`}>
                        <div className="button-solicitudes-grid">
                          <div className="button-solicitudes-icon">
                            <img src={SearchIcon} className="icon-solicitudes-grid" />
                          </div>
                          <div className="button-solicitudes-title">
                            <strong>VER DETALLE DE SOLICITUD</strong>
                          </div>
                        </div>
                      </Link>

                      <br />
                    </div>
                    <div>
                    <Link to={`/solicitudes/${solicitud.id}/ensayos`} className="link_ensayo">
                      <div className="button-solicitudes-grid" id={`${solicitud.id}`}>
                        <div className="button-solicitudes-icon">
                          <img src={FlaskIcon} className="icon-solicitudes-grid" />
                        </div>
                        <div className="button-solicitudes-title">
                          <strong>VER ENSAYOS</strong>
                        </div>
                      </div>
                    </Link>
                    <br></br>
                    </div>

                    <div className="espacio2-bsl">

                    </div>
                  </div>
                )}

                <div className="solicitud_container_info_and_flask" onClick={() => handleSolicitudClick(solicitud.id)}></div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

