import React from 'react';
import '../Style/Login/Signup.css';
import { VerifyForm } from '../Components/Login/VerifyForm';
import Fondo_Phagelab from '../Assets/Fondo_Phagelab.jpg';

export const Verify = () => {
    return (
        <div className='background' style={{
            backgroundImage:`url(${Fondo_Phagelab})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            bachgroundColor: 'white',
        }}>
            <div className='Page'>
                <h1>Verificar nuevo usuario</h1>
                <div className='row'>
                    <VerifyForm />
                </div>
            </div>
        </div>
    )
}