import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from '../Components/Alert';
import axios from 'axios';
import '../Style/Solicitudes/Solicitud.css'
import X_V2 from '../Assets/X_V2.png';
import X_V1 from '../Assets/X_WHITE.png';
import { ClipLoader } from 'react-spinners';

export const Solicitud = (token) => {
    const { id } = useParams();
    const [data, setData] = useState('');
    const [solucitud_seleccionada, setSolicitudSeleccionada] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const navigate = useNavigate();

    function buscarSolicitudPorId(id, datos) {
        for (let i = 0; i < datos.length; i++) {
          if (datos[i].id === id) {
            return datos[i];
          }
        }
        return null; // Si no se encuentra la solicitud, se retorna null
      }

    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
        };
        try {
            setIsLoading(true);
            const response = await axios.get(`${apiUrl}/solicitudes`, config);
            //console.log(response.data);
            setData(response.data);
            setSolicitudSeleccionada(buscarSolicitudPorId(parseInt(id), response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
            alert('Unauthorized');
            setAlertMessage("Error: " + error.message);
            setAlertIsOpen(true);
            } else {
            setAlertMessage("Error fetching data ");
            setAlertIsOpen(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [])


    const deleteSolicitud = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const config = {
            headers: { Authorization: `Bearer ${token.token}` },
            data: { id_solicitud: id } // Set the request body
            };
            setIsLoading(true);
            const response = await axios.delete(`${apiUrl}/solicitudes/${id}`, config);
        
            if (response.data["errorMessage"]) {
            setAlertMessage("Error: " + response.data["errorMessage"] + " Tipo: " + response.data["errorType"]);
            setAlertIsOpen(true);
            return;
            }
            navigate('/solicitudes');
        } catch (error) {
            console.error(error);
            setAlertMessage("Error deleting solicitud: " + error.message);
            setAlertIsOpen(true);
        } finally {
            setIsLoading(false);
        }

    };

    const Modal = () => {
        if (!modalIsOpen) {
            return;
        }
        return (
            <div className="modal">
                <div className="modal-content">
                    <button className="modal-close" onClick={closeModal}>
                        <img src={X_V2} alt="X" />
                    </button>
                    <div className="modal-header">
                        <h4>Eliminar solicitud {id} </h4>    
                    </div>
                    <div className="modal-body">
                        <p>¿Está seguro que desea eliminar esta solicitud?</p>
                    </div>
                    <div className="modal-footer">
                        <button className="modal-button" onClick={closeModal}>Cancelar</button>
                        <button className="modal-button" onClick={deleteSolicitud}>Eliminar</button>
                    </div>
                </div>
            </div>
        );
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
          

    
    return (
    <div className="solicitud_container">
        <Modal/>
        <div>
          <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </div>
        <div className="info-grid" >
            { solucitud_seleccionada && solucitud_seleccionada.id_solicitud ? <h2 className='info-grid-titulo'><strong>SOLICITUD {solucitud_seleccionada.id_solicitud} </strong></h2> : <h2 className='info-grid-titulo'><strong>SOLICITUD {id} </strong></h2> }
            <div className="loading-div-solicitud">
                <ClipLoader
                    color={'#0052D1'}
                    loading={isLoading}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
            <div className="info-grid-fecha">
                <p className="label_header">Fecha de solicitud</p>
                <p className="content_header">{solucitud_seleccionada && solucitud_seleccionada.fecha_solicitud}</p>
            </div>
            <div className="info-grid-solicitante">
                <p className="label_header">Solicitante</p>
                <p className="content_header">{solucitud_seleccionada && solucitud_seleccionada.solicitante}</p>
            </div>
            <div className="info-grid-prioridad">
                <p className="label_header">Prioridad</p>
                <p className="content_header">{solucitud_seleccionada && solucitud_seleccionada.prioridad}</p>
            </div>
            <div className="info-grid-recepcion">
                <p className="label_header">Recepción</p>
                <p className="content_header">{solucitud_seleccionada && solucitud_seleccionada.recepcion}</p>
            </div>
            <div className="info-grid-ensayos">
                <p className="label_header">Ensayos</p>
                <div>
                    <p className="content_header">{solucitud_seleccionada && solucitud_seleccionada.ensayos.map(ensayo => ensayo.id).join(', ')}</p>
                </div>
            </div>
            <div className="info-grid-encargado">
                <p className="label_header">Encargado</p>
                <div>
                    <p className="content_header">{solucitud_seleccionada && solucitud_seleccionada.encargado}</p>
                </div>
            </div>
            <div className="info-grid-dragNdrop">
                <br/>
                <div onClick={openModal} className='button-raw-grid'>
                    <div className='button-raw-icon'>
                    <img src={X_V1} className="icon-raw-grid"/>
                    </div>
                    <div className='button-raw-title'>
                        <strong>ELIMINAR SOLICITUD</strong>
                    </div>
                </div>
            </div>
        </div>

        <div className="muestras_container">
            <table>
                <thead>
                    <tr>
                        <th>Muestra</th>
                        <th>Tipo de muestra</th>
                        <th>Ensayo</th>
                        <th>Cantidad entregada</th>
                        <th>Dilución propuesta</th>
                        <th>Pre procesamiento</th>
                        <th>Observaciones</th>
                    </tr>
                </thead>
                <tbody>
                    {solucitud_seleccionada && solucitud_seleccionada.muestras.map((muestra) => (
                        <tr key={muestra.id}>
                            <td>{muestra.id}</td>
                            <td>{muestra.tipo_muestra}</td>
                            <td>{muestra.id_ensayo}</td>
                            <td>{muestra.cantidad}</td>
                            <td>{muestra.dilucion}</td>
                            <td>{muestra.pre_procesamiento}</td>
                            <td>{muestra.observaciones}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
)
}