import React from 'react';
import '../Style/Login/Login.css';
import { LoginForm } from '../Components/Login/LoginForm';
import { LoginTitle } from '../Components/Login/LoginTitle';
import Fondo_Phagelab from '../Assets/Fondo_Phagelab.jpg';


export const Login = ({ setToken, setAdmin}) => {
    return (
        <div style={{
            backgroundImage:`url(${Fondo_Phagelab})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            bachgroundColor: 'white',
        }} className='background'>
        <div className="Page">
            <div className="Container">
                <div className="Row">
                    <LoginTitle/>
                    <LoginForm setToken={setToken} setAdmin={setAdmin} />
                </div>
            </div>
        </div>
        </div>
    )
}