import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../Style/Login/LoginForm.css';
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import { Alert } from '../Alert';

export const VerifyForm = () => {


    let [email, setEmail] = useState("");
    let [verifyCode, setVerifyCode] = useState("");
    const [alertIsOpen, setAlertIsOpen] = useState(true);
    const [alertMessage, setAlertMessage] = useState("El código de verificación ha sido enviado al correo");

    const navigate = useNavigate();

    const verifyUser = (email, verifyCode, callback) => {
        const userData = {
            Username: email,
            Pool: UserPool,
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(verifyCode, true, callback);
    }

    const handleSubmit = (event) => {
        
        event.preventDefault();

        verifyUser(email, verifyCode, (err, result) => {
            if (err) {
                setAlertMessage("Usuario o código de verificación incorrecto(s)");
                setAlertIsOpen(true);
                console.error(err);
                return;
            }
            console.log("call result: " + result);
            setAlertMessage("Usuario verificado");
            setAlertIsOpen(true);
            navigate("/");
        });
    }

    return (
        <div className="Vertical">
            <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} />            <form onSubmit={handleSubmit}>
                <div className="Horizontal">
                    <h2>Verify Account</h2>
                </div>
                <div className="Horizontal">
                    <div className="Text">
                        <p className="Left">E-mail</p>
                    </div>
                </div>
                <div className="Horizontal">
                    <input type="text" className="input" required placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)}></input>
                </div>
                <div className="Horizontal">
                    <div className="Text">
                        <p className="Left">Código de verificación</p>
                    </div>
                </div>
                <div className="Horizontal">
                    <input type="text" className="input" required placeholder="Enter your verification code" onChange={(e) => setVerifyCode(e.target.value)}></input>
                </div>
                <div className="Horizontal">
                    <div className="ElementSign">
                        <button className="Sign" type="Submit">Verify</button> 
                    </div>  
                </div>
            </form>
        </div>
    )
}
