import React, { useState, useRef } from 'react'
import '../../Style/Solicitudes/DragAndDrop.css'
import BarLoader from "react-spinners/BarLoader";
import {Alert} from '../Alert';

export const DragAndDrop = ({setSolicitudActualizada, token})  => {
    const [data, setData] = useState('');
    const [dragging, setDragging] = useState(false);  
    const [isHovering, setIsHovering] = useState(false);  
    const [showMessage, setShowMessage] = useState(false);
    const [files, setFiles] = useState(''); 
    const [encargado, setEncargado] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const inputRef = useRef(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsHovering(true);
      };
      
    const handleDragOver = (e) => {
        e.preventDefault();
        if (e.dataTransfer.types.includes("Files")) {
            const isFileOver = e.currentTarget.contains(e.relatedTarget);
            setIsHovering(isFileOver);
            setDragging(true);
        }
    };
    
    const handleDragLeave = (e) => {
    e.preventDefault();
    if (!e.dataTransfer.types.includes("Files")) {
        setIsHovering(false);
        setDragging(false);
    }
    };
    
    const handleDrop = (e) => {
    e.preventDefault();
    setFiles(e.dataTransfer.files);
    setIsHovering(false);
    setDragging(false);
    };
    // const [fileByteArray, setFileByteArray] = useState([]);
    const dropzoneClass = `dropzone ${dragging ? 'dragging' : ''} ${isHovering ? 'hovering' : ''}`;

    const handleUpload = async () => {
      if (encargado === '') {
        setAlertMessage("Porfavor ingrese el encargado de la solicitud");
        setAlertIsOpen(true);
        return;
      }
      try {
        const formData = new FormData();
        formData.append('file', files[0]);
        console.log(files[0], "files[0]");
    
        const reader = new FileReader();
    
        reader.onload = async (event) => {
          try {
            const byteArray = new Uint8Array(event.target.result);
            console.log(event.target.result, "filebytearray"); // El array de bytes del archivo
            const resquest_body = {
              "file": byteArray,
              "encargado": encargado,
            };
            console.log(resquest_body);
    
            const apiUrl = process.env.REACT_APP_API_URL;
            const config = {
              method: 'POST',
              body: JSON.stringify(resquest_body),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
              }
            };
            
            setIsLoading(true);
            const response = await fetch(`${apiUrl}/solicitudes`, config);

            console.log(response);
            if (response.ok) {
              const responseData = await response.json();
              setData(responseData);
              setShowMessage(true);
              setSolicitudActualizada(true);
            } 
            else if (response.status === 401) {
              setAlertMessage("No autorizado, porfavor inicie sesión");
              setAlertIsOpen(true);
            }
            else {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
          } catch (error) {
            console.error(error);
            setAlertMessage("Error al subir archivo, es posible que la solicitud ingresada ya exista");
            setAlertIsOpen(true);
          } finally {
            setIsLoading(false); // Set isLoading to false after the upload process completes
          }
        };
    
        reader.readAsArrayBuffer(files[0]);
      } catch (error) {
        console.error(error);
      }
    };
    
    
      
    if (files) {
      return (
        <div className='white-div'>
          <div className={dropzoneClass} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
          <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <BarLoader
              color={'#0052D1'}
              loading={isLoading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            {showMessage ? (
              <div>
                <h3>SOLICITUD CREADA SATISFACTORIAMENTE</h3>
              </div>
            ) : (
              <div>
                <h3>
                  {Array.from(files).map((file, idx) => <li key={idx}>{file.name}</li>)}
                </h3>
                <p className='encargado'>Encargado:</p>
                <div className="column">
                  <input className='input' onChange={e => setEncargado(e.target.value)}></input>
                  <br/>
                  <div className="actions">
                    <button onClick={() => setFiles(null)}>CANCELAR</button>
                    <button onClick={handleUpload}>SUBIR</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

  return (
    <div className='white-div'>
        {!files && (
            <div className = "dropzone" onDragOver = {handleDragOver} onDrop = {handleDrop}>
                <h3>ARRASTRA Y SUELTA</h3>
                <h3>O</h3>
                <input type = "file" multiple onChange={(e) => setFiles(e.target.files)} hidden ref={inputRef}></input>
                <button onClick = {() => inputRef.current.click()}>SELECCIONAR ARCHIVO</button>
            </div>
        )}
      <div>
      </div>
    </div>
  )
}

//https://github.com/iradualbert/drag-and-drop-demo/blob/main/src/components/DragDropFiles.js