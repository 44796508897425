import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../Style/general.css';
import '../Style/Ensayos/Ensayos.css';
import SearchIcon from '../Assets/SEARCH.png';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Alert } from '../Components/Alert';

export const Ensayos = (token) => {
    const { id } = useParams();
    const [data, setData] = useState('');
    const [solucitud_seleccionada, setSolicitudSeleccionada] = useState(null);
    const [intento, setIntento] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();

    function buscarSolicitudPorId(id, datos) {
        for (let i = 0; i < datos.length; i++) {
          if (datos[i].id === id) {
            return datos[i];
          }
        }
        return null; // Si no se encuentra la solicitud, se retorna null
      }

    
    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
        };
        try {
            setIsLoading(true);
            const response = await axios.get(`${apiUrl}/solicitudes`, config);
            //console.log(response.data);
            setData(response.data);
            setSolicitudSeleccionada(buscarSolicitudPorId(parseInt(id), response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
            alert('Unauthorized');
            setAlertMessage("Error: " + error.message);
            setAlertIsOpen(true);
            } else {
            setAlertMessage("Error fetching data ");
            setAlertIsOpen(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    function isNotEmpty( dict ) {
        for ( var name in dict ) {
            return true;
        }
        return false;
    }

    const linkTo = (ensayo, ruta) => async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
        };
        const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${ensayo}/intentos`, config);

        if (response.data["Error"] && ruta === "qpcr") {
            navigate(`/solicitudes/${id}/ensayos/${ensayo}/intentos/0/${ruta}`);
            return;
        }
        if (response.data["Error"]) {
            setAlertMessage(`No hay corridas para el ensayo ${ensayo}, porfavor subir qPCR`);
            setAlertIsOpen(true);
            return;
        }
        const intentos = response.data;
        if (intentos.length > 0) {
            const lastIntentoId = intentos[intentos.length - 1].id;
            navigate(`/solicitudes/${id}/ensayos/${ensayo}/intentos/${lastIntentoId}/${ruta}`);
        }
        
    };

    return (
        <div className='container_principal_ensayos_y_titulo'>
            <h3 className="solicitud_titulo">SOLICITUD {id} </h3>
            <div>
                <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            </div>
            <div className='loading-div-ensayos'>
                <ClipLoader
                    color={'#0052D1'}
                    loading={isLoading}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
            <div className="container_ensayos">
                {isNotEmpty(data) && solucitud_seleccionada.ensayos.map((ensayo) => (
                <div className="container_ensayo" key={ensayo.id}>
                    <div className="container_ensayo_header">
                        <h3 className="ensayo_titulo">Ensayo {ensayo.id_produccion} - {ensayo.id_biomarcador} </h3>
                    </div>
                    <div className='boton_y_descripcion'>
                            <button className='boton_buscar' onClick={linkTo(ensayo.id, "qpcr")}><img src={SearchIcon} alt="Texto alternativo" className='icon-ensayos-grid'/></button>
                        <p className='descripcion_ensayo'>Ver qPCR</p>
                    </div>
                    <div className='boton_y_descripcion'>
                        
                        <button className='boton_buscar' onClick={linkTo(ensayo.id, "curva_estandar")}><img src={SearchIcon} alt="Texto alternativo" className='icon-ensayos-grid'/></button>
                        <p className='descripcion_ensayo'>Ver Curva Estándar</p>
                    </div>
                    <div className='boton_y_descripcion'>
                        
                        <button className='boton_buscar' onClick={linkTo(ensayo.id, "cuantificacion")}><img src={SearchIcon} alt="Texto alternativo" className='icon-ensayos-grid'/></button>
                        <p className='descripcion_ensayo'>Ver Cuantificación</p>
                    </div>
                    <div className='boton_y_descripcion'>
                        
                        <button className='boton_buscar' onClick={linkTo(ensayo.id, "consolidado")}><img src={SearchIcon} alt="Texto alternativo" className='icon-ensayos-grid'/></button>
                        <p className='descripcion_ensayo'>Ver Consolidado Por Muestra</p>
                    </div>
                    <div className='boton_y_descripcion'>
                        
                        <button className='boton_buscar' onClick={linkTo(ensayo.id, "reporte")}><img src={SearchIcon} alt="Texto alternativo" className='icon-ensayos-grid'/></button>
                        <p className='descripcion_ensayo'>Ver Reporte</p>
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
};
