// create component showing Logo.png
import React from 'react';
import X_V2 from '../Assets/X_V2.png';

export const Alert = ({ alertIsOpen, setAlertIsOpen, alertMessage }) => {
    if (!alertIsOpen) {
        return;
    }
    return (
      <div className="alert">
          <div className="alert-content">
              <button className="alert-close" onClick={() => setAlertIsOpen(false)}>
                  <img src={X_V2} alt="X" />
              </button>
              <div className="alert-body">
                  <p>{alertMessage}</p>
              </div>
              <div className="alert-footer">
                  <button className="alert-button" onClick={() => setAlertIsOpen(false)}>
                      OK
                  </button>
              </div>
          </div>
      </div>
    );
};
