import React, { useState, useRef } from 'react';
import '../../Style/Solicitudes/DragAndDropRAW.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BarLoader } from 'react-spinners';

export const DragAndDropRaw = (token) => {
  const [data, setData] = useState('');
  const [dragging, setDragging] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [files, setFiles] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const { id, id_ensayo } = useParams();

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsHovering(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (e.dataTransfer.types.includes('Files')) {
      const isFileOver = e.currentTarget.contains(e.relatedTarget);
      setIsHovering(isFileOver);
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    if (!e.dataTransfer.types.includes('Files')) {
      setIsHovering(false);
      setDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setFiles(e.dataTransfer.files);
    setIsHovering(false);
    setDragging(false);
  };

  const dropzoneClass = `dropzone ${dragging ? 'dragging' : ''} ${isHovering ? 'hovering' : ''}`;

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('file', files[0]);

      const reader = new FileReader();

      reader.onload = async (event) => {
        const byteArray = new Uint8Array(event.target.result);
        console.log(event.target.result, 'filebytearray'); // El array de bytes del archivo
        const request_body = {
          file: byteArray,
        };
        console.log(request_body);

        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.token}`,
          }
        };
        setIsLoading(true);
        const response = await axios.post(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/qpcr`, request_body, config);
        setData(response.data);

        console.log('response', response.status);
        if (response.status === 200) {
          setShowMessage(true);
        }
      };

      reader.readAsArrayBuffer(files[0]);
    } catch (error) {
      console.error(error);
      alert('Error al subir el archivo');
      // Display error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  if (files) {
    return (
      <div className={dropzoneClass} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
        {showMessage ? (
          <div>
            <h3>Archivo qPCR actualizado satisfactoriamente</h3>
          </div>
        ) : (
          <div>
            <div className='loading-div-qpcr-upload'>
              <BarLoader
                  color={'#0052D1'}
                  loading={isLoading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
              />
            </div>
            <h3>
              {Array.from(files).map((file, idx) => (
                <li key={idx}>{file.name}</li>
              ))}
            </h3>
            <div className="actions">
              <button onClick={() => setFiles(null)}>Cancelar</button>
              <button onClick={handleUpload}>Subir</button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div>
        {!files && (
          <div className="dropzone1" onDragOver={handleDragOver} onDrop={handleDrop}>
            <h3 className="title-drag">Arrastra y suelta tu archivo qPCR</h3>
            <h3 className="title-drag">ó</h3>
            <input type="file" multiple onChange={(e) => setFiles(e.target.files)} hidden ref={inputRef}></input>
            <button className="button-drag" onClick={() => inputRef.current.click()}>
              Seleccionar archivo qPCR
            </button>
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
};
