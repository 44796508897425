import React from 'react'
import { DragAndDrop } from './DragAndDrop'
import '../../Style/Solicitudes/Create_solicitud.css'

export const CreateSolicitud = ({setSolicitudActualizada, token}) => {
  return (
    <div className="Create_solicitud">
        <h3 className="nueva_solicitud_title">NUEVA SOLICITUD</h3>
        <div className="Create_solicitud-Upload">
            <DragAndDrop setSolicitudActualizada={setSolicitudActualizada} token={token}></DragAndDrop>
        </div>

    </div>
  )
}

