import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../Style/Login/LoginForm.css';
import UserPool from "./UserPool";
import { Alert } from '../Alert';

export const SignupForm = () => {

    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    const [alertIsOpen, setAlertIsOpen] = useState(true);
    const [alertMessage, setAlertMessage] = useState("La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un símbolo");

    const navigate = useNavigate();

    const validateEmail = (email) => {
        var re = /\S+@pht\.cl$/;
        return re.test(email);
    };

    const handleSubmit = (event) => {
        
        event.preventDefault();

        // const validation = validateEmail(email);
        const validation = true
        if (validation === false) {
            setAlertMessage("Email debe pertenecer al dominio de PhageLab");
            setAlertIsOpen(true);
            return;
        }

        UserPool.signUp(email, password, [], null, (err, data) => {
            if (err) {
                console.error(err);
                setAlertMessage(err.message);
                setAlertIsOpen(true);
                return;
            }
            console.log(data);
            navigate("/verify");
        });
    }

    return (
        <div className="Vertical">
            <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} />
            <form onSubmit={handleSubmit}>
                <div className="Horizontal">
                    <h2>Sign Up</h2>
                </div>
                <div className="Horizontal">
                    <div className="Text">
                        <p className="Left">E-mail</p>
                    </div>
                </div>
                <div className="Horizontal">
                    <input type="text" className="input" required placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)}></input>
                </div>
                <div className="Horizontal">
                    <div className="Text">
                        <p className="Left">Password</p>
                    </div>
                </div>
                <div className="Horizontal">
                    <input type="password" className="input" required placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)}></input>
                </div>
                <div className="Horizontal">
                    <div className="ElementSign">
                        <button className="Sign" type="Submit">Sign Up</button> 
                    </div>  
                </div>
            </form>
        </div>
    )
}
