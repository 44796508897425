import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../../Style/Login/LoginForm.css';
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import { Alert } from '../Alert';

export const ForgotPasswordForm = () => {

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("Se ha enviado un correo para cambiar la contraseña");
    const [opened, setOpened] = useState(false);

    const forgotPassword = (email, callback) => {
        const userData = {
            Username: email,
            Pool: UserPool,
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.forgotPassword({
            onSuccess: function (result) {
                console.log('call result: ' + result);
                callback(null, result);
            },
            onFailure: function (err) {
                console.log(err);
                callback(err, null);
            },

        });
    }

    const navigate = useNavigate();


    let [email, setEmail] = useState("");
    

    const handleSubmit = (event) => {
        
        event.preventDefault();

        forgotPassword(email, (err, data) => {
            if (err) {
                console.log(err);
            } else {
                setAlertIsOpen(true);
                console.log(data);
                setOpened(true);
            }
        }
        );
    }

    useEffect(() => {
        if (opened && !alertIsOpen) {
            navigate("/changePassword");
        }
    }, [alertIsOpen]);

    return (
        <div className="Vertical">
            <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <form onSubmit={handleSubmit}>
                <div className="Horizontal">
                    <h2>Forgot Password?</h2>
                </div>
                <div className="Horizontal">
                    <div className="Text">
                        <p className="Left">E-mail</p>
                    </div>
                </div>
                <div className="Horizontal">
                    <input type="text" className="input" required placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)}></input>
                </div>
                <div className="Horizontal">
                    <div className="ElementSign">
                        <button className="Sign" type="Submit">Submit</button> 
                    </div>  
                </div>
            </form>
        </div>
    )
}
