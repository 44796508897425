import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from '../Components/Alert';
import axios from 'axios';
import '../Style/Consolidado.css'
import { ClipLoader } from 'react-spinners';

export const Consolidado = ({token, setId}) => {
    const { id, id_ensayo, id_intento} = useParams();
    setId(id);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [alertIsOpen, setAlertIsOpen] = useState(false); // alerta
    const [alertMessage, setAlertMessage] = useState(""); // mensaje de alerta
    const [intentoId, setIntentoId] = useState(id_intento); // variable de intento
    const [intentos, setIntentos] = useState([]); // lista de intentos

    const navigate = useNavigate();

    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
          };
        setIsLoading(true);
        const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/consolidacion`, config);

        if (response.data["errorMessage"]) {
            setAlertMessage(response.data["errorMessage"]);
            setAlertIsOpen(true);
            setIsLoading(false);
            return;
        }
        if (response.data["Error"]) {
            setAlertMessage(response.data["Error"]);
            setAlertIsOpen(true);
            setIsLoading(false);
            return;
        }
        if (response.data["statusCode"] === 404) {
            setAlertMessage(response.data["message"]);
            setAlertIsOpen(true);
            setIsLoading(false);
            return;
        }

        setData(response.data);
        console.log("response.data");
        console.log(response.data);
        setIsLoading(false);
    }
    // useEffect(() => {
    //     fetchData()
    // }, [])

    const fetchIntentos = async () => {
        console.log("Fetching intentos");
        const apiUrl = process.env.REACT_APP_API_URL;
        const config = {
            headers: { Authorization: `Bearer ${token.token}` },
        };
        const response = await axios.get(`${apiUrl}/solicitudes/${id}/ensayos/${id_ensayo}/intentos`, config);
        console.log(response.data);
    
        if (response.data["Error"]) {
            setAlertMessage(response.data["Error"]);
            setAlertIsOpen(true);
            setIntentos([]);
            return;
        }
        setIntentos(response.data);
    };

    useEffect(() => { // hook para cargar intentos al cargar la pagina
        fetchIntentos();
    }, []);

    const handleIntento = (e) => {
        setIntentoId(e.target.value);
        console.log(intentoId);
    };

    useEffect(() => {
        navigate(`/solicitudes/${id}/ensayos/${id_ensayo}/intentos/${intentoId}/consolidado`);
        fetchData();
    }, [intentoId]);

          

    
    return (
    <div className="solicitud_container_2">
        <div className='loading-div-ensayos'>
            <ClipLoader
                color={'#0052D1'}
                loading={isLoading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
        <div>
            <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </div>
        
        <div className='title-and-button-ensayos-consolidacion-select'>
            <h2 className='info-grid-titulo'><strong>Consolidado por Muestra</strong></h2>
            <select className='select-intento' value={intentoId} onChange={handleIntento}>
                {intentos && intentos.map((i, index) => (
                <option className='option-intento' key={i["id"]} value={i["id"]}>Intento {index+1}</option>
                ))}
                {intentos.length === 0 && <option className='option-intento' value={0}>Intento N/A</option>}
            </select>
        </div>
        <div className="muestras_container">
            <table>
                <thead>
                    <tr>
                        <th>Muestra</th>
                        <th>Detección</th>
                        <th>Concentración Promedio</th>
                        <th>Unidad</th>
                        <th>Desviación Estándar</th>
                        <th>Concentración Mínima entre réplicas</th>
                        <th>Réplicas</th>
                        <th>CV entre réplicas</th>
                    </tr>
                </thead>
                <tbody>
                    { data != [] && data.map((muestra) => (
                        <tr key={muestra.id}>
                            <td>{muestra.id_muestra}</td>
                            <td>{muestra.deteccion}</td>
                            <td>{muestra.concentracion_promedio}</td>
                            <td>{muestra.unidad}</td>
                            <td>{muestra.desviacion_estandar}</td>
                            <td>{muestra.concentracion_minima}</td>
                            <td>{muestra.replicas}</td>
                            <td>{muestra.cv_replicas}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
)
}
