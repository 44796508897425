// create a navbar component

import '../Style/Navbar.css';
import { Link } from 'react-router-dom';
import { LogoPhage } from './LogoPhage';
import UserPool from './Login/UserPool';
import React, { useState } from 'react';
import Settings from '../Assets/SETTINGS V1.png';
import { useNavigate } from 'react-router-dom';

export const Navbar = ({ setToken, admin, setAdmin, token, id, setId }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);


  const Logout = () => {
    setToken(null);
    setAdmin(false);
    setId(null);
    localStorage.removeItem('qcmdtoken');
    localStorage.removeItem('qcmdadmin');
    UserPool.getCurrentUser().signOut();
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClick = () => {
    setId(null);
  };

  const handleLogoClick = () => {
    navigate('/solicitudes');
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  
  const handleGoBack = () => {
    navigate(-1);
  }

  return (
    <div className="Navbar">
      <div
        className="Navbar-Title"
      >
        <div
         onClick={handleLogoClick}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         style={{ cursor: isHovered ? 'pointer' : 'default' }}
         >
          <LogoPhage />
         </div>

      </div>

      <div className="Navbar-Links">
        {id && (
          <Link to={`/solicitudes/${id}/ensayos`} className="Navbar-Link" onClick={handleClick}>
            Solicitud {id}
          </Link>
        )}
        <div className="Navbar-Dropdown" onClick={toggleMenu}>
          <button className="Navbar-DropdownButton"><img src={Settings} className='Settings'></img></button>
          {menuOpen && (
            <div className="Navbar-DropdownContent">
              {token && (
                <Link to="/solicitudes" className="Navbar-DropdownLink" onClick={handleClick}>
                  Solicitudes
                </Link>
              )}
              {/* <Link to="/about" className="Navbar-DropdownLink" onClick={handleClick}>
                About
              </Link> */}
              {admin && (
                <Link to="/signup" className="Navbar-DropdownLink" onClick={handleClick}>
                  Crear Usuario
                </Link>
              )}
              {token ? (
                <Link
                  to="/"
                  className="Navbar-DropdownLink"
                  onClick={() => Logout()}
                >
                  Log Out
                </Link>
              ) : (
                <Link to="/" className="Navbar-DropdownLink" onClick={handleClick}>
                  Log In
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
