import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../Style/Login/LoginForm.css';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import { Link } from 'react-router-dom';
import { Alert } from '../Alert';

export const LoginForm = ({setToken, setAdmin}) => {


    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                console.log("onSuccess:", data);
                console.log("Id Token:", data.getIdToken().getJwtToken());
                console.log(typeof(data.getIdToken().getJwtToken()));
                const groups = data.getIdToken().payload['cognito:groups'];
                if (groups && groups.length > 0) {
                console.log("Groups:", groups[0]);
                if (groups[0] === 'Administrador') {
                    console.log("Admin: true");
                    setAdmin(true);
                    localStorage.setItem('qcmdadmin', true);
                }
                } else {
                console.log("Groups: none");
                }

                setToken(data.getIdToken().getJwtToken());
                localStorage.setItem('qcmdtoken', data.getIdToken().getJwtToken());

                navigate("/solicitudes");
            },
            onFailure: (err) => {
                setAlertMessage("Usuario o contraseña incorrecta");
                setAlertIsOpen(true);
            },
            newPasswordRequired: (data) => {
                setAlertMessage("Nueva contraseña requerida");
                setAlertIsOpen(true);
                console.log("newPasswordRequired:", data);
            },
        });
    }

    return (
        <div className="Vertical">
            <Alert alertIsOpen={alertIsOpen} setAlertIsOpen={setAlertIsOpen} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <form onSubmit={handleSubmit}>
                <div className="Horizontal-title">
                    <h2>Login</h2>
                </div>
                <div className="Horizontal">
                    <div className="Text">
                        <p className="Left">E-mail</p>
                    </div>
                </div>
                <div className="Horizontal">
                    <input type="text" required onChange={(e) => setEmail(e.target.value)} className="input"></input>
                </div>
                <div className="Horizontal">
                    <div className="Text">
                        <p className="Left">Password</p>
                    </div>
                </div>
                <div className="Horizontal">
                    <input type="password" required onChange={(e) => setPassword(e.target.value)} className="input"></input>
                </div>
                <div className="Horizontal">
                    <div className="Link">
                        <Link to="/ForgotPassword">Forgot Password?</Link> 
                    </div>  
                </div>
                <div className="Horizontal">
                    <div className="ElementSign">
                        <button className="Sign" type="Submit">INICIAR SESIÓN</button> 
                    </div>  
                </div>
            </form>
        </div>
    )
}
