// create component saying welcome to the user
import React from 'react';
import '../../Style/Login/LoginTitle.css';

export const LoginTitle = () => {
    return (
        <div className="LoginTitle">
            <h1>Plataforma de registro y seguimiento de muestras.</h1>
        </div>
    )
}